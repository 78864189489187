<template>
    <ui-card title="Retry refund" class="retry-refund">
        <div class="text-center">
            Are you sure you want to retry this refund?
        </div>
        <img src="@/assets/img/refunds/retry_refunds.svg" alt="Retry refund" class="retry-refund__img" />
        <ui-button
            class="align-self-center"
            variant="primary"
            @click="retryRefund"
            :loading="loading"
            :disabled="!CAN(['write_refunds'])"
        >
            Retry
        </ui-button>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    components: { UiCard, UiButton },
    data() {
        return {
            loading: false,
            refund_id: this.$route.query.id,
        };
    },
    methods: {
        async retryRefund() {
            try {
                this.loading = true;
                await this.$axios.patch('/_/refunds/retry-id', {
                    ids: [this.refund_id],
                });
                this.$toast.success('Refund has been retried');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
            finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.retry-refund {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__img {
        margin: 1rem 0;
        width: 50%;
    }
}
</style>