<template>
    <ui-card title="Refund information">
        <ui-list>
            <ui-list-item v-if="refund.transaction" title="Transaction ID:">
                <template #text>
                    <div>
                        <router-link class="text-decoration-none" :to="{ name: 'transactions-info', query: { id: refund.transaction.id } }">
                            {{ refund.transaction.transaction_id }}
                        </router-link>
                    </div>
                </template>
            </ui-list-item>
            <ui-list-item v-if="refund.gateway" title="Gateway:">
                <template #text>
                    <span class="text-uppercase">{{ refund.destination }}</span>
                </template>
            </ui-list-item>
            <ui-list-item v-if="refund.status" title="Status:">
                <template #text>
                    <ui-status :status="refund.status"></ui-status>
                </template>
            </ui-list-item>
            <ui-list-item v-if="refund.reason" title="Reason:">
                <template #text>
                    <ui-status :status="refund.reason"></ui-status>
                </template>
            </ui-list-item>
            <ui-list-item v-if="refund.error" title="Error:">
                <template #text>
                    <span class="text-capitalize">{{ refund.error }}</span>
                </template>
            </ui-list-item>
            <ui-list-item v-if="refund.type" title="Type:">
                <template #text>
                    <span class="text-uppercase">{{ refund.type }}</span>
                </template>
            </ui-list-item>
            <ui-list-item v-if="refund.amount" title="Total:">
                <template #text>
                    {{ _currency(refund.amount, refund.currency) }}
                </template>
            </ui-list-item>
            <ui-list-item title="Date:">
                <template #text>
                    {{ getRefundAtDate(refund.created_at) }}
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiStatus
    },
    props: {
        refund: { 
            type: Object, 
            default: null 
        }
    },
    methods: {
        getRefundAtDate(date) {
            if (!isNaN(date)) {
                return this.$dayjs(date * 1000).format('LLLL');
            }
            return this.$dayjs(date).format('LLLL');
        },
    }
}
</script>