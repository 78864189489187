<template>
    <div class="refunds-info">
        <b-button class="mb-3 col-auto" variant="light" @click="$router.go(-1)">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <div v-if="refund" class="refunds-info__number">
            Operation {{ refund.id }}
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <ui-card-skeleton v-if="loadingRefundInfo" />
                <div v-else>
                    <refund-information v-if="refund" :refund="refund" />
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div>
                    <ui-card-skeleton v-if="loadingRefundInfo" />
                    <div v-else>
                        <account-information v-if="account" :account="account" />
                    </div>
                </div>
                <div v-if="CAN(['write_refunds'])">
                    <ui-card-skeleton v-if="loadingRefundInfo" />
                    <div v-else>
                        <retry-refund v-if="refund && refund.status == 'processing'" :refund="refund" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RetryRefund from '@/components/refunds/RetryRefund.vue';
import AccountInformation from '@/components/refunds/AccountInformation.vue';
import RefundInformation from '@/components/refunds/RefundInformation.vue'
import UiCardSkeleton from '@/components/ui/skeleton/CardSkeleton';

export default {
    components: {
        AccountInformation,
        RefundInformation,
        UiCardSkeleton,
        RetryRefund,
    },
    data() {
        return {
            refund: null,
            account: null,
            loadingRefundInfo: false,
            refund_id: this.$route.query.id,
        };
    },
    mounted() {
        this.getRefunds();
    },
    methods: {
        async getRefunds() {
            try {
                this.loadingRefundInfo = true;
                const { data } = await this.$axios.get(`/_/refunds/${this.refund_id}`);
                this.refund = data;
                this.account = data.account;
            } catch (error) {
                console.error(error);
            }finally {
                this.loadingRefundInfo = false;
            }
        },
    },
};
</script>

<style lang="scss">
.refunds-info {
    &__number {
        font-size: 0.95rem;
        margin-bottom: 1rem;
    }
}
</style>
